"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatronBadge = exports.StripeErrorCode = exports.LiveMessageEvent = exports.TabType = exports.VercelDeploymentState = exports.ServerContainerStatus = exports.ServerStatus = exports.WindowOrientation = exports.CommentsFilterOption = exports.SandboxGitState = void 0;
var SandboxGitState;
(function (SandboxGitState) {
    SandboxGitState["SYNCED"] = "synced";
    SandboxGitState["CONFLICT_SOURCE"] = "conflict in source";
    SandboxGitState["CONFLICT_PR_BASE"] = "conflict in pr base";
    SandboxGitState["OUT_OF_SYNC_SOURCE"] = "out of sync with source";
    SandboxGitState["OUT_OF_SYNC_PR_BASE"] = "out of sync with pr base";
    SandboxGitState["SYNCING"] = "syncing";
    SandboxGitState["RESOLVED_SOURCE"] = "resolved source";
    SandboxGitState["RESOLVED_PR_BASE"] = "resolved pr base";
})(SandboxGitState || (exports.SandboxGitState = SandboxGitState = {}));
var CommentsFilterOption;
(function (CommentsFilterOption) {
    CommentsFilterOption["ALL"] = "All";
    CommentsFilterOption["OPEN"] = "Open";
    CommentsFilterOption["RESOLVED"] = "Resolved";
})(CommentsFilterOption || (exports.CommentsFilterOption = CommentsFilterOption = {}));
var WindowOrientation;
(function (WindowOrientation) {
    WindowOrientation["VERTICAL"] = "vertical";
    WindowOrientation["HORIZONTAL"] = "horizontal";
})(WindowOrientation || (exports.WindowOrientation = WindowOrientation = {}));
var ServerStatus;
(function (ServerStatus) {
    ServerStatus["INITIALIZING"] = "initializing";
    ServerStatus["CONNECTED"] = "connected";
    ServerStatus["DISCONNECTED"] = "disconnected";
})(ServerStatus || (exports.ServerStatus = ServerStatus = {}));
var ServerContainerStatus;
(function (ServerContainerStatus) {
    ServerContainerStatus["INITIALIZING"] = "initializing";
    ServerContainerStatus["CONTAINER_STARTED"] = "container-started";
    ServerContainerStatus["SANDBOX_STARTED"] = "sandbox-started";
    ServerContainerStatus["STOPPED"] = "stopped";
    ServerContainerStatus["HIBERNATED"] = "hibernated";
    ServerContainerStatus["ERROR"] = "error";
})(ServerContainerStatus || (exports.ServerContainerStatus = ServerContainerStatus = {}));
/**
 * Can't find where these types are documented on the Vercel side, but we're using these states
 * to show active deployment status to the user.
 */
var VercelDeploymentState;
(function (VercelDeploymentState) {
    VercelDeploymentState["DEPLOYING"] = "DEPLOYING";
    VercelDeploymentState["INITIALIZING"] = "INITIALIZING";
    VercelDeploymentState["DEPLOYMENT_ERROR"] = "DEPLOYMENT_ERROR";
    VercelDeploymentState["BOOTED"] = "BOOTED";
    VercelDeploymentState["BUILDING"] = "BUILDING";
    VercelDeploymentState["READY"] = "READY";
    VercelDeploymentState["BUILD_ERROR"] = "BUILD_ERROR";
    VercelDeploymentState["FROZEN"] = "FROZEN";
    VercelDeploymentState["ERROR"] = "ERROR";
})(VercelDeploymentState || (exports.VercelDeploymentState = VercelDeploymentState = {}));
var TabType;
(function (TabType) {
    TabType["MODULE"] = "MODULE";
    TabType["DIFF"] = "DIFF";
})(TabType || (exports.TabType = TabType = {}));
var LiveMessageEvent;
(function (LiveMessageEvent) {
    LiveMessageEvent["SAVE"] = "save";
    LiveMessageEvent["JOIN"] = "join";
    LiveMessageEvent["MODULE_STATE"] = "module_state";
    LiveMessageEvent["USER_ENTERED"] = "user:entered";
    LiveMessageEvent["USER_LEFT"] = "user:left";
    LiveMessageEvent["USERS_CHANGED"] = "users:changed";
    LiveMessageEvent["MODULE_SAVED"] = "module:saved";
    LiveMessageEvent["MODULE_CREATED"] = "module:created";
    LiveMessageEvent["MODULE_MASS_CREATED"] = "module:mass-created";
    LiveMessageEvent["MODULE_UPDATED"] = "module:updated";
    LiveMessageEvent["MODULE_DELETED"] = "module:deleted";
    LiveMessageEvent["EXTERNAL_RESOURCES"] = "sandbox:external-resources";
    LiveMessageEvent["DIRECTORY_CREATED"] = "directory:created";
    LiveMessageEvent["DIRECTORY_UPDATED"] = "directory:updated";
    LiveMessageEvent["DIRECTORY_DELETED"] = "directory:deleted";
    LiveMessageEvent["USER_SELECTION"] = "user:selection";
    LiveMessageEvent["USER_CURRENT_MODULE"] = "user:current-module";
    LiveMessageEvent["USER_VIEW_RANGE"] = "user:view-range";
    LiveMessageEvent["LIVE_MODE"] = "live:mode";
    LiveMessageEvent["LIVE_CHAT_ENABLED"] = "live:chat_enabled";
    LiveMessageEvent["LIVE_ADD_EDITOR"] = "live:add-editor";
    LiveMessageEvent["LIVE_REMOVE_EDITOR"] = "live:remove-editor";
    LiveMessageEvent["OPERATION"] = "operation";
    LiveMessageEvent["CONNECTION_LOSS"] = "connection-loss";
    LiveMessageEvent["DISCONNECT"] = "disconnect";
    LiveMessageEvent["OWNER_LEFT"] = "owner_left";
    LiveMessageEvent["CHAT"] = "chat";
    LiveMessageEvent["NOTIFICATION"] = "notification";
})(LiveMessageEvent || (exports.LiveMessageEvent = LiveMessageEvent = {}));
var StripeErrorCode;
(function (StripeErrorCode) {
    StripeErrorCode["REQUIRES_ACTION"] = "requires_action";
})(StripeErrorCode || (exports.StripeErrorCode = StripeErrorCode = {}));
var PatronBadge;
(function (PatronBadge) {
    PatronBadge["ONE"] = "patron-1";
    PatronBadge["TWO"] = "patron-2";
    PatronBadge["THREE"] = "patron-3";
    PatronBadge["FOURTH"] = "patron-4";
})(PatronBadge || (exports.PatronBadge = PatronBadge = {}));
