import React from 'react';

export const CommentsIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path fill="currentColor" d="M10 14a6 6 0 116 6h-6z" />
      <path
        fill="currentColor"
        d="M19.697 19.945a5.265 5.265 0 005.04 3.74H30V18.42a5.263 5.263 0 00-7.021-4.962 6.996 6.996 0 01-3.281 6.486z"
      />
    </g>
  </svg>
);

export const SearchIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 26c5.523 0 10-4.477 10-10S25.523 6 20 6s-10 4.477-10 10 4.477 10 10 10zm-.46-15a4.54 4.54 0 102.404 8.393L24.8 22.45 26.25 21l-2.857-3.056A4.54 4.54 0 0019.54 11zm-2.49 4.54a2.49 2.49 0 114.98 0 2.49 2.49 0 01-4.98 0z"
      clipRule="evenodd"
    />
  </svg>
);

export const DeployIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M19.86 12.19l4.057-4.057c1.586-1.586 5.289-2.896 6.568-1.617s-.058 4.955-1.644 6.54l-4.056 4.057c-.34.34-.318.895-.152 1.347.997 2.718-3.89 7.541-3.89 7.541l-1.95-1.948 1.83-2.779-1.83.83-3.896-3.897.803-1.857-2.751 1.857L11 16.26s4.8-4.91 7.514-3.917c.451.165 1.007.187 1.347-.153z"
        clipRule="evenodd"
      />
      <path d="M13.608 25.29a2.178 2.178 0 10-2.643-2.635L10 26.259z" />
    </g>
  </svg>
);

export const ExplorerIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 7a1 1 0 011-1h7v6h6v13a1 1 0 01-1 1H14a1 1 0 01-1-1zm9-1l5 5h-5z"
      clipRule="evenodd"
    />
  </svg>
);

export const ServerIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.922 7a.922.922 0 00-.922.922v3.156c0 .51.413.922.922.922h18.156c.51 0 .922-.413.922-.922V7.922A.922.922 0 0029.078 7zm.578 1a.5.5 0 000 1h7a.5.5 0 000-1zm-.578 5a.922.922 0 00-.922.922v3.156c0 .51.413.922.922.922h18.156c.51 0 .922-.413.922-.922v-3.156a.922.922 0 00-.922-.922zm.846.857a.5.5 0 100 1h7a.5.5 0 100-1zM10 19.922c0-.51.413-.922.922-.922h18.156c.51 0 .922.413.922.922v3.156c0 .51-.413.922-.922.922H10.922a.922.922 0 01-.922-.922zm1 .578a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5z"
      clipRule="evenodd"
    />
  </svg>
);

export const GithubIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      d="M17.242 26.224c-.08 0-.169-.008-.267-.026l.04-.446h.396a.39.39 0 00.159-.086.426.426 0 00.125-.241.561.561 0 00.008-.1c0-.242-.003-.468-.006-.771l-.013-1.25c-3.617.668-4.466-1.893-4.5-2.003-.579-1.467-1.371-1.84-1.373-1.841l-.03-.017c-1.826-1.254.21-1.231.217-1.231h.014c1.426.099 2.188 1.424 2.23 1.498.434.749.968 1.117 1.499 1.269.77.22 1.544-.005 2.002-.197.063-.379.167-.706.297-.984.088-.187.188-.352.295-.495-1.382-.196-2.768-.597-3.85-1.514-1.203-1.017-2.019-2.652-2.023-5.314a5.683 5.683 0 01.359-2.036c.23-.607.564-1.152.98-1.634a4.422 4.422 0 01-.227-1.154c-.044-.645.025-1.487.402-2.453l.009-.018s.164-.141.396-.154c.153-.008.385 0 .697.063.608.123 1.54.462 2.826 1.325a12.308 12.308 0 011.563-.317 13.052 13.052 0 013.492-.005c.532.071 1.057.175 1.566.312 2.581-1.744 3.772-1.362 3.776-1.361l.104.031.04.102c.382.966.454 1.806.412 2.45a4.438 4.438 0 01-.223 1.155c.418.48.753 1.023.984 1.63.235.614.363 1.293.364 2.037.004 2.669-.808 4.306-2.009 5.323-1.081.916-2.467 1.317-3.852 1.513.163.213.309.476.423.789.153.42.25.934.251 1.54.002 1.082-.004 2.208-.009 2.953l-.002.748c0 .034.003.07.01.108.018.09.057.176.125.237.037.034.084.061.143.08l.42-.002.042.446c-.1.02-.194.029-.282.03-.086 0-.165-.01-.237-.025l-7.532.013c-.071.015-.148.023-.23.023z"
    />
  </svg>
);

export const InfoIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      d="M20.54 25a.681.681 0 00.158-.074l6.174-3.69c.734-.438 1.128-.885 1.128-2.084v-6.444c0-.248-.016-.447-.063-.629l-7.397 4.434zm-8.477-12.921l7.397 4.434V25a.514.514 0 01-.15-.074l-6.182-3.69c-.726-.438-1.128-.885-1.128-2.084v-6.444c0-.248.016-.447.063-.629zM20.004 7c.443 0 .9.143 1.359.406l.068.04 5.567 3.326c.155.088.284.177.394.282l.04.04-7.428 4.426-7.436-4.425c.11-.109.233-.198.378-.286l.063-.037 4.07-2.442h.002l1.488-.883c.434-.262.868-.415 1.296-.443l.071-.003z"
    />
  </svg>
);

export const LiveIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M15.222 19.113c2.193 0 3.976-2.108 4.023-4.533a.981.981 0 00.178-.48.92.92 0 00-.202-.698c-.114-2.225-.684-4.592-3.033-4.876l-.027-.003c-.681-.083-2.23-.27-2.548.665-2.144-.26-2.41 2.012-2.425 3.889-.248.153-.378.513-.297.886a.944.944 0 00.308.528c.005 2.463 1.804 4.622 4.023 4.622zm1.775 7.354c-.016-2.005-.032-4.022 2.003-5.304C17.645 18.895 5.439 19.5 8.487 27H17l-.003-.533z" />
      <path d="M30.348 13.247c0 1.05-.374 2.087-.816 2.878-.1.179.08.482.262.786.199.333.398.667.226.839-.47.47-1.582.414-2.482.347-.59.517-1.303.819-2.07.819-.861 0-1.654-.382-2.284-1.022l-.514.24c-1.337-.177-2.162-2.377-1.844-4.914.319-2.537 1.66-4.45 2.997-4.272.307.065.584.15.835.251.575-.474 1.257-.75 1.988-.75 2.045 0 3.702 2.148 3.702 4.798zM18.602 26.85h13.36s.965-6.448-6.76-6.617c-7.726-.168-6.6 6.617-6.6 6.617z" />
    </g>
  </svg>
);
export const SettingsIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 40 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.253 6h-3.076l-.463 1.85a8.413 8.413 0 00-2.13.931l-1.567-.94-2.176 2.176.94 1.567a8.414 8.414 0 00-1.01 2.435L10 14.462v3.077l1.772.442c.209.872.553 1.692 1.01 2.435l-.94 1.567 2.175 2.176 1.566-.94c.744.456 1.564.8 2.436 1.01L18.46 26h3.077l.443-1.772a8.411 8.411 0 002.435-1.01l1.567.94 2.176-2.175-.94-1.567c.456-.743.8-1.563 1.01-2.435L30 17.538v-3.077l-1.772-.442a8.411 8.411 0 00-1.01-2.436l.94-1.566-2.175-2.176-1.567.94a8.408 8.408 0 00-2.736-1.076zM20 20.286a4.286 4.286 0 100-8.572 4.286 4.286 0 000 8.572z"
      clipRule="evenodd"
    />
  </svg>
);

export const DockerIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 90 90" {...props}>
    <path
      fill="currentColor"
      d="M88.326 37.69c-.232-.195-2.327-1.785-6.826-1.785a21.7 21.7 0 0 0-3.53.31c-.852-5.934-5.778-8.804-5.972-8.959l-1.202-.698-.776 1.125a16.582 16.582 0 0 0-2.133 4.964c-.815 3.374-.31 6.555 1.396 9.27-2.056 1.163-5.391 1.435-6.09 1.474H8.12a2.599 2.599 0 0 0-2.598 2.598 39.246 39.246 0 0 0 2.404 14.157c1.9 4.964 4.732 8.649 8.378 10.898C20.414 73.565 27.123 75 34.687 75c3.413 0 6.826-.31 10.2-.93A42.096 42.096 0 0 0 58.23 69.22a36.518 36.518 0 0 0 9.076-7.447c4.383-4.925 6.981-10.433 8.882-15.32h.775c4.77 0 7.718-1.9 9.347-3.529 1.087-1.008 1.9-2.25 2.483-3.646l.349-1.008-.815-.582Z"
    />
    <path
      fill="currentColor"
      d="M13.239 41.8h7.369c.349 0 .66-.271.66-.659v-6.593c0-.35-.272-.66-.66-.66h-7.37c-.348 0-.659.272-.659.66v6.593c.04.388.31.66.66.66Zm10.162 0h7.369c.349 0 .659-.271.659-.659v-6.593c0-.35-.271-.66-.66-.66h-7.368c-.35 0-.66.272-.66.66v6.593c.039.388.31.66.66.66Zm10.355 0h7.37c.348 0 .659-.271.659-.659v-6.593c0-.35-.272-.66-.66-.66h-7.369a.654.654 0 0 0-.66.66v6.593c0 .388.272.66.66.66Zm10.2 0h7.37c.349 0 .659-.271.659-.659v-6.593c0-.35-.272-.66-.66-.66h-7.369c-.349 0-.659.272-.659.66v6.593c0 .388.31.66.66.66ZM23.4 32.376h7.37c.349 0 .659-.31.659-.66v-6.593a.654.654 0 0 0-.66-.66h-7.368c-.35 0-.66.272-.66.66v6.593c.039.35.31.66.66.66Zm10.356 0h7.37a.68.68 0 0 0 .659-.66v-6.593a.654.654 0 0 0-.66-.66h-7.369a.654.654 0 0 0-.66.66v6.593c0 .35.272.66.66.66Zm10.2 0h7.37a.68.68 0 0 0 .659-.66v-6.593a.68.68 0 0 0-.66-.66h-7.369c-.349 0-.659.272-.659.66v6.593c0 .35.31.66.66.66Zm0-9.464h7.37c.349 0 .659-.271.659-.66V15.66a.68.68 0 0 0-.66-.659h-7.369c-.349 0-.659.271-.659.66v6.593c0 .349.31.66.66.66ZM54.234 41.8h7.37c.349 0 .659-.271.659-.659v-6.593c0-.35-.272-.66-.66-.66h-7.369a.654.654 0 0 0-.659.66v6.593c.039.388.31.66.66.66Z"
    />
  </svg>
);

export const VSCodeIcon = props => (
  <svg width={40} height={32} fill="none" viewBox="0 0 90 90" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.275 91.6411C64.6137 92.1589 66.1404 92.1259 67.4914 91.4801L84.9915 83.1151C86.8306 82.2361 88 80.3874 88 78.3593V21.6409C88 19.6127 86.8306 17.764 84.9918 16.885L67.4914 8.52002C65.7184 7.67239 63.6429 7.88001 62.0863 9.004C61.8642 9.16456 61.6523 9.34383 61.4535 9.5414L27.9518 39.9027L13.3591 28.8992C12.0007 27.8749 10.1006 27.9589 8.83891 29.0989L4.15857 33.3281C2.61533 34.7226 2.61356 37.1343 4.15475 38.531L16.81 50L4.15475 61.469C2.61356 62.8658 2.61533 65.2775 4.15857 66.672L8.83891 70.901C10.1006 72.0412 12.0007 72.125 13.3591 71.1009L27.9518 60.0972L61.4535 90.4587C61.9834 90.9854 62.6059 91.3822 63.275 91.6411ZM66.763 30.8322L41.3427 50L66.763 69.1678V30.8322Z"
      fill="currentColor"
    />
  </svg>
);

export const AIIcon = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.1168 7.87962C23.946 7.70871 23.8127 7.50396 23.7255 7.27853L23.725 7.27701L21.7654 2.18151L21.7643 2.17862C21.7443 2.12586 21.7085 2.08049 21.662 2.04858C21.6155 2.01667 21.5602 1.99972 21.5038 2H21.4942C21.4378 1.99972 21.3825 2.01666 21.336 2.04858C21.2894 2.08049 21.2537 2.12586 21.2337 2.17862L21.2325 2.18166L19.2715 7.27863L19.271 7.28C19.1839 7.50544 19.0506 7.71018 18.8796 7.88109M24.1168 7.87962L24.7847 7.21179M24.1168 7.87962C24.2878 8.05053 24.4925 8.18387 24.718 8.27099L24.7194 8.27147L29.8163 10.2325C29.8701 10.2531 29.9171 10.2899 29.9498 10.3374C29.9825 10.385 30 10.4413 30 10.4989C30 10.5567 29.9825 10.613 29.9498 10.6606C29.9171 10.708 29.8708 10.7445 29.817 10.7652L24.7209 12.7249L24.7195 12.7255C24.494 12.8127 24.2893 12.946 24.1184 13.1168C23.9474 13.2878 23.8141 13.4925 23.727 13.718L23.7264 13.7194L21.7655 18.8163C21.7447 18.8701 21.708 18.9171 21.6606 18.9498C21.613 18.9825 21.5567 19 21.4989 19C21.4413 19 21.385 18.9825 21.3374 18.9498C21.29 18.9171 21.2534 18.8708 21.2327 18.817L19.273 13.7209L19.2724 13.7194C19.1853 13.494 19.052 13.2892 18.8811 13.1184C18.7102 12.9474 18.5054 12.8141 18.28 12.727L18.2786 12.7264L13.2126 10.7773L13.2018 10.7732C13.1421 10.7511 13.0908 10.7112 13.0548 10.6588C13.0194 10.6075 13.0002 10.5468 13 10.4844C13.0015 10.4303 13.0189 10.3778 13.0499 10.3335C13.0817 10.2882 13.1263 10.2535 13.178 10.2339L13.1816 10.2325L18.2771 8.273L18.2785 8.27241C18.504 8.18529 18.7087 8.052 18.8796 7.88109M18.8796 7.88109L18.2118 7.21326"
      fill="currentColor"
    />
    <path
      d="M9.84717 21.1503C9.72658 21.0297 9.6325 20.8852 9.57092 20.726L9.57058 20.725L8.18733 17.1281L8.18658 17.1261C8.17242 17.0888 8.14717 17.0568 8.11433 17.0343C8.0815 17.0118 8.0425 16.9998 8.00267 17H7.99592C7.95608 16.9998 7.91708 17.0118 7.88425 17.0343C7.85133 17.0568 7.82617 17.0888 7.812 17.1261L7.81117 17.1282L6.42692 20.7261L6.42658 20.7271C6.36508 20.8862 6.271 21.0307 6.15033 21.1514M9.84717 21.1503L10.3186 20.6789M9.84717 21.1503C9.96783 21.271 10.1123 21.3651 10.2715 21.4266L10.2725 21.4269L13.8703 22.8112C13.9083 22.8258 13.9415 22.8517 13.9646 22.8853C13.9877 22.9188 14 22.9586 14 22.9993C14 23.04 13.9877 23.0798 13.9646 23.1133C13.9415 23.1468 13.9088 23.1726 13.8708 23.1872L10.2736 24.5705L10.2726 24.5709C10.1134 24.6325 9.96892 24.7266 9.84825 24.8472C9.72758 24.9678 9.6335 25.1123 9.572 25.2715L9.57158 25.2725L8.18742 28.8703C8.17275 28.9083 8.14683 28.9415 8.11333 28.9646C8.07975 28.9877 8.04 29 7.99925 29C7.95858 29 7.91883 28.9877 7.88525 28.9646C7.85175 28.9415 7.82592 28.9088 7.81133 28.8708L6.428 25.2736L6.42758 25.2725C6.36608 25.1134 6.272 24.9688 6.15133 24.8483C6.03075 24.7276 5.88617 24.6335 5.72708 24.572L5.72608 24.5716L2.15008 23.1958L2.14242 23.1928C2.10033 23.1773 2.06408 23.1491 2.03867 23.1121C2.01367 23.0759 2.00017 23.033 2 22.989C2.00108 22.9508 2.01333 22.9138 2.03525 22.8825C2.05767 22.8505 2.08917 22.826 2.12567 22.8122L2.12817 22.8112L5.725 21.428L5.726 21.4276C5.88517 21.3661 6.02967 21.272 6.15033 21.1514M6.15033 21.1514L5.67892 20.68"
      fill="currentColor"
    />
  </svg>
);
