"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTheme = void 0;
const collectStyles_1 = require("./collectStyles");
const makeTheme = theme => {
    const prismTheme = (0, collectStyles_1.collectAllSettings)(theme.tokenColors);
    const json = Object.assign({ plain: {
            color: theme.colors['editor.foreground'],
            backgroundColor: theme.colors['editor.background'],
        } }, prismTheme);
    return json;
};
exports.makeTheme = makeTheme;
